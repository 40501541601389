<template>
  <div id="InfotainmentPanel">
    <div class="row">
      <div class="col-12 col-lg-6">
        <FeatureHeader
          name="Infotainment"
          :score="vehicle.Infotainment_Score"
        />
      </div>
    </div>
    <div class="row text-center group-1 mb-3">
      <div class="col">
        <div class="row text-left">
          <h3 class="col">Features</h3>
        </div>
        <div class="row">
          <div
            class="col-6 col-lg-2 pb-2 pb-lg-0"
            v-for="(feature, idx) in group1Features"
            :key="idx"
            :class="{
              disabled: !feature.isAvailable,
            }"
          >
            <div class="row">
              <div class="col">
                <img
                  class="img-fluid"
                  :src="getIconImage(feature.iconName)"
                  v-show="feature.isAvailable || !feature.disabledOverride"
                />
                <span
                  v-show="
                    !feature.isAvailable &&
                    feature.disabledOverride
                  "
                  >N/A</span
                >
              </div>
            </div>
            <div class="row">
              <label class="col">{{ feature.display }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-6 col-lg-4 pb-4 pb-lg-0"
        v-for="(category, idx) in group2Features"
        :key="idx"
      >
        <div class="row">
          <h4 class="col"><i v-show="category.iconClass" :class="category.iconClass"></i>{{ category.name }}</h4>
        </div>
        <div
          class="row"
          v-for="(feature, idx2) in category.features"
          :key="idx2"
        >
          <div :class="['col', { 'not-available': !feature.isAvailable }]">
            <img v-show="feature.isAvailable" src="~Images/check.webp" />
            <span v-show="!feature.isAvailable"> &#8212; </span>
            {{ feature.display }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const FeatureHeader = () => import("Components/VehicleDetail/FeatureHeader");
export default {
  props: {
    vehicle: Object,
  },
  computed: {
    group1Features() {
      return [
        {
          iconName: "apple_carplay.png",
          display: "Apple Options",
          isAvailable: this.convertYesNoToBool(this.vehicle.Apple_CarPlay),
          disabledOverride: "unavailable",
        },
        {
          iconName: "android_auto.png",
          display: "Android Options",
          isAvailable: this.convertYesNoToBool(this.vehicle.Android_Autos),
          disabledOverride: "unavailable",
        },
        {
          iconName: "stream_music_icon.svg",
          display: "Streaming Music",
          isAvailable: this.convertYesNoToBool(this.vehicle.Streaming_Music),
        },
        {
          iconName: "stream_video_icon.svg",
          display: "Streaming Video",
          isAvailable: this.convertYesNoToBool(this.vehicle.Films),
        },
        {
          iconName: "video_games_icon.svg",
          display: "Video Games",
          isAvailable: this.convertYesNoToBool(this.vehicle.Games),
        },
        {
          iconName: "internet_icon.svg",
          display: "Internet",
          isAvailable: this.convertYesNoToBool(this.vehicle.Online_Search),
        },
      ];
    },
    group2Features() {
      return [
        {
          name: "Display options",
          iconClass: "fas fa-tablet-alt",
          features: [
            {
              display: `${this.displayOptions.displaySize}-inch console display`,
              isAvailable: true,
            },
            {
              display: "Passenger display",
              isAvailable: this.displayOptions.passengerDisplay,
            },
          ],
        },
        {
          name: "Navigation options",
          iconClass: "fas fa-location-arrow",
          features: [
            {
              display: "Standard navigation",
              isAvailable: this.navigationOptions.standardNav,
            },
            {
              display: "Charging planner",
              isAvailable: this.navigationOptions.chargingPlanner,
            },
          ],
        },
        {
          name: "Additional features",
          img: "",
          features: [
            {
              display: "Voice control",
              isAvailable: this.additionalFeatures.voiceControl,
            },
            {
              display: "Over-the-air updates",
              isAvailable: this.additionalFeatures.otaUpdates,
            },
            {
              display: "Proprietary phone pairing",
              isAvailable: this.additionalFeatures.proprietaryPhonePairing,
            },
          ],
        },
      ];
    },
    displayOptions() {
      return {
        displaySize: this.vehicle.Display_Size,
        passengerDisplay: this.convertYesNoToBool(
          this.vehicle.Passenger_Display
        ),
      };
    },
    navigationOptions() {
      return {
        standardNav: this.convertYesNoToBool(this.vehicle.Navigation),
        chargingPlanner: this.convertYesNoToBool(this.vehicle.chargingPlanner),
      };
    },
    additionalFeatures() {
      return {
        voiceControl: this.convertYesNoToBool(this.vehicle.Voice_Control),
        otaUpdates: this.convertYesNoToBool(this.vehicle.OTA_Updates),
        proprietaryPhonePairing: this.convertYesNoToBool(
          this.vehicle.Proprietary_Phone_Pairing
        ),
      };
    },
  },
  methods: {
    convertYesNoToBool(value) {
      return value !== "No";
    },
    getIconImage(name) {
      return require(`Images/${name}`);
    },
  },
  components: {
    FeatureHeader,
  },
};
</script>
<style scoped lang="scss">
.not-available {
  color: #bababa;
}
h3 {
  font-family: $primary-header-font-family;
  font-size: 1rem;
}
h4 {
  color: #162b32;
  font-size: 1rem;
}

.group-1 {
  background-color: #edf2f5;
  padding-top: 20px;
}

label {
  color: #333333;
  font-size: 0.75rem;
}

.disabled {
  label {
    color: #333333;
  }
  img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(180deg)
      brightness(100%) contrast(98%);
  }
  span {
    font-family: $primary-header-font-family;
    font-size: 1.5rem;
    font-weight: 900;
  }
}

.fas {
  padding-right: 5px;
  color: #858585;
  font-size: 0.95rem;


}
</style>
