var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"InfotainmentPanel"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6"},[_c('FeatureHeader',{attrs:{"name":"Infotainment","score":_vm.vehicle.Infotainment_Score}})],1)]),_c('div',{staticClass:"row text-center group-1 mb-3"},[_c('div',{staticClass:"col"},[_vm._m(0),_c('div',{staticClass:"row"},_vm._l((_vm.group1Features),function(feature,idx){return _c('div',{key:idx,staticClass:"col-6 col-lg-2 pb-2 pb-lg-0",class:{
            disabled: !feature.isAvailable,
          }},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(feature.isAvailable || !feature.disabledOverride),expression:"feature.isAvailable || !feature.disabledOverride"}],staticClass:"img-fluid",attrs:{"src":_vm.getIconImage(feature.iconName)}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                  !feature.isAvailable &&
                  feature.disabledOverride
                ),expression:"\n                  !feature.isAvailable &&\n                  feature.disabledOverride\n                "}]},[_vm._v("N/A")])])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col"},[_vm._v(_vm._s(feature.display))])])])}),0)])]),_c('div',{staticClass:"row"},_vm._l((_vm.group2Features),function(category,idx){return _c('div',{key:idx,staticClass:"col-6 col-lg-4 pb-4 pb-lg-0"},[_c('div',{staticClass:"row"},[_c('h4',{staticClass:"col"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(category.iconClass),expression:"category.iconClass"}],class:category.iconClass}),_vm._v(_vm._s(category.name))])]),_vm._l((category.features),function(feature,idx2){return _c('div',{key:idx2,staticClass:"row"},[_c('div',{class:['col', { 'not-available': !feature.isAvailable }]},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(feature.isAvailable),expression:"feature.isAvailable"}],attrs:{"src":require("Images/check.webp")}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!feature.isAvailable),expression:"!feature.isAvailable"}]},[_vm._v(" — ")]),_vm._v(" "+_vm._s(feature.display)+" ")])])})],2)}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row text-left"},[_c('h3',{staticClass:"col"},[_vm._v("Features")])])
}]

export { render, staticRenderFns }